<!-- 警报 发警报 -->
<template>
  <div class="message-center-container" v-if="messageCenterVisible">
    <div class="message-content">
      <div class="row row1">
        <div class="col col1">
          <el-select v-model="value" placeholder="请选择">
            <el-option value="1" label="收件箱"> </el-option>
          </el-select>
        </div>
        <div class="col col2">
          <el-select v-model="value" placeholder="请选择">
            <el-option value="1" label="全部来源"> </el-option>
          </el-select>
        </div>
        <div class="col col3">
          <el-select v-model="value" placeholder="请选择">
            <el-option value="1" label="全部类型"> </el-option>
          </el-select>
        </div>
        <div class="col col4">
          <span>发送时间</span>
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            popper-class="message-date-picker"
          >
          </el-date-picker>
          <!-- <div class="date">
            <span>开始日期</span>
            <span>-</span>
            <span>结束日期</span>
          </div> -->
        </div>
        <div class="col col5">
          <span><img src="~@/assets/home/搜索.svg" alt="" srcset=""/></span>
          <input type="text" placeholder="请输入搜索内容" />
        </div>
      </div>
      <div class="content">
        <div class="row row2">
          <div class="table">
            <div class="head">
              <div class="th th1 checkbox">
                <tis-checkbox
                  v-model="checked"
                  @change="selectedAll"
                ></tis-checkbox>
              </div>
              <div class="th th2">消息来源</div>
              <div class="th th3">消息类型</div>
              <div class="th th4">内容</div>
              <div class="th th5">接收时间</div>
            </div>
            <div class="tbody">
              <div
                class="tr"
                @click="onReply"
                v-for="(item, index) in list"
                v-show="!item.hidden"
                :key="index"
              >
                <div class="td checkbox" @click.stop>
                  <!-- <img src="~@/assets/img_090801.png" alt="" srcset="" /> -->
                  <tis-checkbox v-model="item.checked"></tis-checkbox>
                </div>
                <div class="td">{{ item.title }}</div>
                <div class="td tag">
                  <span>{{ item.tag }}</span>
                </div>
                <div class="td">
                  {{ item.content }}
                </div>
                <div class="td">04/02 09:40:04</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row row3">
          <div class="left">
            <div class="m-btn btn1" @click="onDelete(0)">批量删除</div>
            <div class="m-btn btn2" @click="onDelete('all')">全部删除</div>
            <div class="m-btn btn3">标记已读</div>
          </div>
          <div class="right">
            <el-pagination
              background
              layout="prev, pager, next,total"
              :total="1000"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      checked: false,
      messageDetailVisible: false,
      options: [
        {
          value: "1",
          label: "收件箱",
        },
        {
          value: "2",
          label: "收件箱",
        },
      ],
      value: "1",
      value1: false,
      date: null,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    onReply(index) {
      this.$tis.bus.messageDetailVisible = true;
    },
    showMessageDetail() {
      this.messageDetailVisible = true;
    },
    getList() {
      this.list = [
        {
          title: "态势感知1",
          tag: "报警",
          time: "10分钟",
          hidden: false,
          checked: false,
          content:
            "请于2021年5月15日12:00分于x地，于x部队配合，执行x任务，所需资源包…",
        },
        {
          title: "态势感知2",
          tag: "报警",
          time: "10分钟",
          hidden: false,
          checked: false,
          content:
            "请于2021年5月15日12:00分于x地，于x部队配合，执行x任务，所需资源包…",
        },
        {
          title: "态势感知3",
          tag: "报警",
          time: "10分钟",
          hidden: false,
          checked: false,
          content:
            "请于2021年5月15日12:00分于x地，于x部队配合，执行x任务，所需资源包…",
        },
        {
          title: "态势感知4",
          tag: "报警",
          time: "10分钟",
          hidden: false,
          checked: false,
          content:
            "请于2021年5月15日12:00分于x地，于x部队配合，执行x任务，所需资源包…",
        },
      ];
    },
    selectedAll() {
      this.list.forEach((item) => {
        item.checked = this.checked;
      });
    },
    onDelete(type) {
      if (type === "all") {
        this.list = [];
      } else {
        this.list.forEach((item) => {
          item.hidden = !!item.checked;
        });
      }
    },
  },
  computed: {
    messageCenterVisible() {
      return this.$tis.bus.messageCenterVisible;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-select-dropdown {
  li {
    font-size: 28px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    opacity: 0.4;
    &.el-select-dropdown__item.selected {
      color: #fff;
      opacity: 1;
    }
  }
}
.message-center-container {
  padding: 36px;
  box-sizing: border-box;
  height: 100%;
  .message-content {
    height: 100%;
    overflow: hidden;
  }
  .row {
    width: 100%;
    &.row1 {
      padding: 1px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      margin-bottom: 35px;
      box-sizing: border-box;
      select,
      .el-select {
        height: 100%;
        width: 100%;
        background: none;
        color: #fff;
        ::v-deep .el-input {
          height: 100%;
          .el-input__inner {
            height: 100%;
            background: none;
            font-size: 28px;
            border: none;
          }
        }
      }
      .col {
        width: 220px;
        height: 100%;
        background: rgba(59, 59, 55, 0.5);
        border: 1px solid #979797;
        font-size: 28px;
        color: #ffffff;
      }
      .col4 {
        width: 625px;
        height: 100%;
        padding: 0 20px;
        padding-right: 0;
        box-sizing: border-box;
        line-height: 80px;
        display: flex;
        > span {
          margin-right: 10px;
          width: 120px;
        }
        .el-date-editor {
          // transform: scale(1.4);
          
        }
        .date {
          // opacity: 0.35;
          // font-family: HiraginoSansGB-W3;
          // font-size: 28px;
          // color: #ffffff;
        }
        ::v-deep .el-date-editor {
          height: 100%;
          flex: 1;
          background: none;
          border: none;
          // align-items: center;
          // display: flex;
          .el-input__icon {
            font-size: 24px;
          }
          .el-range-input {
            opacity: 0.7;
            font-size: 30px;
            color: #ffffff;
            background: none;
          }
          .el-range-separator {
            font-size: 24px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 70px;
          }
        }
      }
      .col5 {
        width: 428px;
        display: flex;
        align-items: center;
        img {
          width: 38px;
          margin: 0 20px;
        }
        input {
          height: 100%;
          background: none;
          opacity: 0.6;
          font-family: HiraginoSansGB-W3;
          font-size: 28px;
          color: #ffffff;
        }
      }
    }
  }
  .content {
    background: #232221;
    width: 100%;
    height: calc(100% - 110px);
    position: relative;
    .table {
      .head {
        background: #232221;
        opacity: 0.85;
        font-size: 30px;
        display: flex;
        color: #ffffff;
        height: 96px;
        line-height: 96px;
        .th1 {
          width: 5%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .th2 {
          width: 10%;
        }
        .th3 {
          width: 10%;
        }
        .th4 {
          width: 55%;
        }
        .th5 {
          width: 20%;
        }
      }
      .tbody {
        font-family: PingFangSC-Regular;
        font-size: 28px;
        color: #ffffff;
        .tr {
          background: #2a2927;
          font-size: 28px;
          display: flex;
          color: #ffffff;
          height: 96px;
          line-height: 96px;
          &:nth-child(even) {
            background: #232221;
          }
          .td:nth-of-type(1) {
            width: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .td:nth-of-type(2) {
            width: 10%;
          }
          .td:nth-of-type(3) {
            width: 10%;
            span {
              display: inline-block;
              background: #cc4e4e;
              width: 80px;
              height: 48px;
              line-height: 48px;
              text-align: center;
              font-family: HYk2gj;
              font-size: 24px;
              color: #ffffff;
            }
          }
          .td:nth-of-type(4) {
            width: 55%;
            overflow: hidden;
            padding-right: 40px;
            box-sizing: border-box;
          }
          .td:nth-of-type(5) {
            width: 20%;
          }
        }
      }
    }
    .row3 {
      display: flex;
      align-items: center;
      height: 80px;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 24px;
      position: absolute;
      width: 100%;
      bottom: 30px;
      .left {
        display: flex;
        .m-btn {
          width: 190px;
          line-height: 80px;
          text-align: center;
          font-size: 28px;
          color: #ffffff;
          border: 1px solid #585858;
          margin-right: 24px;
        }
      }
      .right {
        flex: 1;
        ::v-deep .el-pagination {
          .btn-prev,
          .btn-next,
          .more {
            width: 80px;
            height: 80px;
            line-height: 80px;
            .el-icon {
              font-size: 28px;
            }
          }
          .el-pagination__total {
            font-size: 28px;
            line-height: 80px;
            height: 80px;
            margin-left: 20px;
          }
          .number {
            width: 80px;
            height: 80px;
            line-height: 80px;
            font-family: EurostileBold;
            font-size: 28px;
            color: #1d1d1d;
            color: #fff;
            &.active {
              color: #000;
            }
          }
        }
      }
    }
  }
}

.checkbox {
  ::v-deep .el-checkbox__inner {
    width: 26px;
    height: 26px;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    transform: rotate(45deg) scaleY(1) scale(2.5) translate(70%, 0%);
  }
}
</style>

<style lang="scss">
.message-date-picker {
  // transform: scale(1.5);
  // margin-left: 100px;
  // margin-top: 100px !important;
  width: 880px;
  .el-date-table {
    font-size: 24px;
  }
  .el-date-range-picker__header div {
    font-size: 26px;
  }
  .el-date-table td{
    width: 46px;
  }
  .el-date-table td div{
    height: 53px;
  }
  .el-date-table td span{
    width: 46px;
    height: 46px;
    line-height: 46px;
  }
}
</style>
