<template>
  <el-time-select
    placeholder="起始时间"
    v-model="time"
    popper-class="disturb-time"
    :picker-options="{
      start: '08:30',
      step: '00:15',
      end: '18:30',
    }"
  >
  </el-time-select>
</template>
<script>
export default {
  components: {},
  props: {
    placeholder: String,
  },
  data() {
    return {
      time: null,
    };
  },
  methods: {},
  computed: {},
};
</script>
<style lang="scss">
.disturb-time {
  .time-select-item {
    font-size: 28px;
    opacity: 0.5;
    line-height: 30px;
  }
}
</style>
