<template>
  <div>
    <!-- 页面跳转 -->
    <LoginPagePad v-if="!hasLogin" @login-success="login" @login-fail="login">
      login
    </LoginPagePad>
    <BasicLayout v-show="hasLogin">
      <StatusBarContainer slot="status-bar" />
      <!-- 控制面板，全部应用 -->
      <ControlPanelPad />
      <AppViewContainer />
      <!-- 左下角 浮动工具 -->
      <FloatingToolPad v-if="false" />
      <!-- 全部应用里面的添加浮窗 -->
      <FloatingWindowPad />
      <!-- 应用界面  设置等 -->
      <ApplicationInterfacePad>
        <!-- 消息设置 -->
        <MessageSettingPad />
        <!-- 报警页面 -->
        <AlarmPad />
        <!-- 消息中心 -->
        <MessageCenterPad />
      </ApplicationInterfacePad>
      <!-- 搜索 -->
      <SearchBoxPad />
      <VoiceBoxContainerPad/>
      <!-- 首页抽屉消息 -->
      <MessageBoxPad />
      <!-- 消息回复 -->
      <MessageReplyDialogPad />
    </BasicLayout>
  </div>
</template>

<script>
import BasicLayout from "./layouts/BasicLayout";
import StatusBarContainer from "./layouts/StatusBarContainer";
import AppViewContainer from "./layouts/AppViewContainer.vue";
import ControlPanelPad from "./../components/ControlPanelPad.vue";
import FloatingToolPad from "./../components/FloatingToolPad.vue";
import FloatingWindowPad from "./../components/FloatingWindowPad.vue";
import ApplicationInterfacePad from "./../components/ApplicationInterfacePad.vue";
import AlarmPad from "./../components/AlarmPad.vue";
import SearchBoxPad from "./../components/SearchBoxPad.vue";
import MessageBoxPad from "./../components/MessageBoxPad.vue";
import MessageSettingPad from "./../components/MessageSettingPad.vue";
import MessageCenterPad from "./../components/MessageCenterPad.vue";
import MessageReplyDialogPad from "./../components/MessageReplyDialogPad.vue";
import LoginPagePad from "../components/LoginPagePad.vue";
import VoiceBoxContainerPad from "./layouts/VoiceBoxContainerPad.vue";

export default {
  components: {
    LoginPagePad,
    ControlPanelPad,
    FloatingToolPad,
    MessageCenterPad,
    BasicLayout,
    AppViewContainer,
    StatusBarContainer,
    FloatingWindowPad,
    ApplicationInterfacePad,
    AlarmPad,
    SearchBoxPad,
    MessageBoxPad,
    MessageSettingPad,
    MessageReplyDialogPad,
    VoiceBoxContainerPad
  },
  data() {
    return {
      hasLogin: sessionStorage.getItem('token') === 'success',
    };
  },
  mounted() {
    this.$tis.bus.deviceType = "pad"; // pad 标识
  },
  computed: {
    alertSettingPageVisible() {
      return this.$tis.bus.alertSettingPageVisible;
    },
  },
  methods: {
    login({ username, password }) {
      console.log("用户名：", username);
      console.log("密码：", password);
      // 登录逻辑
      this.hasLogin = true;
      sessionStorage.setItem('token', 'success')
    },
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  display: none;
}
</style>

<style lang="scss" scoped>
button.app {
  width: 136px;
  height: 62px;
  font-size: 30px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
</style>
