<!-- 警报 发警报 -->
<template>
  <div class="message-setting-container" v-if="messageSettingVisible">
    <div class="wrap">
      <div class="w-left menu">
        <div
          :class="{item: true, selected: item.selected}"
          v-for="(item, index) in menuList"
          :key="index"
          @click="onSelected(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="w-right c-content">
        <div class="head">
          <div class="h-title">
            <span>{{ menuList[menuIndex].name }}</span>
          </div>
        </div>
        <template v-if="menuIndex === 0">
          <div class="s-content">
            <div class="row row1">
              <div class="left">主题</div>
              <div class="right">
                <el-radio-group v-model="radio1">
                  <el-radio :label="1">默认主题</el-radio>
                  <el-radio :label="2">蓝色主题</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
        </template>
        <template v-if="menuIndex === 1">
          <div class="s-content">
            <div class="row row1">
              <div class="left">消息语音提示</div>
              <div class="right">
                <el-radio-group v-model="radio1">
                  <el-radio :label="1">无</el-radio>
                  <el-radio :label="2">提示音</el-radio>
                  <el-radio :label="3">语音播报</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="row row2">
              <div class="left">消息精确度</div>
              <div class="right">
                <el-radio-group v-model="radio2">
                  <el-radio :label="1">模糊</el-radio>
                  <el-radio :label="2">精确</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="row row3">
              <div class="left">右侧消息提醒</div>
              <div class="right">
                <el-radio-group v-model="radio3">
                  <el-radio :label="1">关闭</el-radio>
                  <el-radio :label="2">开启</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="row row4">
              <p>
                当前席位处于在线状态时，常规消息通知都会从右侧弹出，重要消息保持强提示方式
              </p>
            </div>
            <div class="row row5"></div>
            <div class="row row6">
              <div class="left">勿扰模式</div>
              <div class="right">
                <el-radio-group v-model="radio4">
                  <el-radio :label="1">关闭</el-radio>
                  <el-radio :label="2">开启</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="row row7">
              <div class="left">勿扰时间段</div>
              <div class="right">
                <div class="input">
                  <TimeSelectPad placeholder="起始时间" />
                </div>
                <span>至</span>
                <div class="input">
                  <TimeSelectPad placeholder="结束时间" />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="menuIndex === 2">
          <div class="s-content">
            <!-- 语音设置 -->
            <div class="row">
              <div class="left">默认模式</div>
              <div class="right-content right1">
                <el-radio-group v-model="voiceSetting.iatoneType">
                  <el-radio :label="3">实时流模式</el-radio>
                  <el-radio :label="1">命令词模式</el-radio>
                  <el-radio :label="2">自由说模式</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="left">语音播报</div>
              <div class="right-content">
                <el-radio-group v-model="voiceSetting.broadcast">
                  <el-radio :label="0">关</el-radio>
                  <el-radio :label="1">开</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="left">人声</div>
              <div class="right-content">
                <el-radio-group v-model="voiceSetting.role">
                  <el-radio :label="0">男声</el-radio>
                  <el-radio :label="1">女声</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="left">语速</div>
              <div class="right-content_volume">
                <el-slider :min="0" :max="10" v-model="voiceSetting.speed"></el-slider>
              </div>
            </div>
            <div class="row">
              <div class="left">语调</div>
              <div class="right-content_volume">
                <el-slider :min="0" :max="10" v-model="voiceSetting.tone"></el-slider>
              </div>
            </div>
            <div class="row">
              <span class="left">音量</span>
              <div class="right-content_volume">
                <el-slider :min="0" :max="10" v-model="voiceSetting.volume"></el-slider>
              </div>
            </div>
            <div class="head">
              <div class="h-title">
                <span>快 捷 指 令</span>
              </div>
            </div>
            <div class="content content6">
              <el-tabs v-model="value6">
                <template>
                  <el-tab-pane
                    v-for="(item, index) in instructList"
                    :label="item.name"
                    :name="index.toString()"
                    :key="index"
                  >
                    <el-button class="button-new-tag" size="small" @click="showAddTagDialog(index)"> + </el-button>
                    <el-tag
                      v-for="(tag, i) in item.list"
                      :key="tag.name"
                      closable
                      @click="showEditDialog(index, i)"
                      @close="onTabClose(index, i)"
                      v-show="!tag.hidden"
                    >
                      {{ tag.name }}
                    </el-tag>
                  </el-tab-pane>
                </template>
              </el-tabs>
              <div class="search-content">
                <el-input v-model="searchValue" @input="onSearchInput" placeholder="搜索命令词"></el-input>
                <el-button>搜索</el-button>
              </div>
            </div>
          </div>
        </template>
        <template v-if="menuIndex === 3">
          <div class="s-content">
            <div class="row row1">
              <div class="left">当前活跃内存</div>
              <div class="right">
                26.65MB
              </div>
            </div>
            <div class="row row1">
              <div class="left">已分配内存</div>
              <div class="right">
                26.65MB
              </div>
            </div>
            <div class="row row1">
              <div class="left">可用最大内存</div>
              <div class="right">
                26.65MB
              </div>
            </div>
            <div class="w-title">
              <div class="wrap">
                <div class="thead">
                  应用列表
                </div>
                <div class="tbody">
                  <div class="tr">
                    主界面应用（状态：运行 加载耗时：111ms）
                  </div>
                  <div class="tr">
                    应用示例（状态：未加载）
                  </div>
                  <div class="tr">
                    默认示例（状态：未加载）
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <el-dialog title="添加标签" :visible.sync="addTagVisible" :modal="false" custom-class="dialogBox" width="35%">
      <div class="content">
        <el-input placeholder="请输入名称" v-model="tagValue"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTagVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddTag">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="编辑标签" :visible.sync="editTagVisible" :modal="false" custom-class="dialogBox" width="35%">
      <div class="content">
        <el-input placeholder="请输入名称" v-model="editTagValue"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editTagVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditTag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import TimeSelectPad from './TimeSelectPad'
export default {
  components: {
    TimeSelectPad,
  },
  props: {},
  data() {
    return {
      radio1: 1,
      radio2: 1,
      radio3: 1,
      radio4: 1,
      startTime: '',
      endTime: '',
      menuIndex: 0,
      menuList: [
        {
          name: '显示设置',
          selected: true,
        },
        {
          name: '消息设置',
          selected: false,
        },
        {
          name: '语音设置',
          selected: false,
        },
        {
          name: '监控模块',
          selected: false,
        },
      ],
      value6: 0,
      // 语音设置
      voiceSetting: {
        role: 1, // 人声
        speed: 5, // 语速
        tone: 5, // 语调
        volume: 5, // 音量
        iatoneType: 2, // 模式
        broadcast: 0, // 语音播报
      },
      instructList: [
        {
          name: '快捷指令',
          list: [
            {name: '打开数据库指挥', hidden: false},
            {name: '打开数据库指挥2', hidden: false},
          ],
        },
        {
          name: '分屏操作',
          list: [],
        },
        {
          name: '其他',
          list: [],
        },
      ],
      commandList: [],
      addTagVisible: false,
      editTagVisible: false,
      editTagValue: null,
      tagValue: null,
      activateAddBtnIndex: '',
      searchValue: '',
    }
  },
  watch: {
    radio_theme() {
      document.documentElement.setAttribute('data-theme', this.radio_theme)
      this.$tis.changeTheme(this.radio_theme === '' ? 'default' : 'blue')
      // 设置localstorage
      localStorage.setItem('TIS_DESIGN_THEME', this.radio_theme === '' ? 'YELLOW' : 'BLUE')
    },
    voiceSetting: {
      handler(val) {
        this.$tis.voice.setSetting(val)
      },
      deep: true,
    },
  },
  created() {
    this.radio_theme = document.documentElement.getAttribute('data-theme') || ''
    this.getBnf()
  },
  mounted() {
    this.getSetting()
    this.menuIndex = this.$tis.bus.visibleWidgetSysSettingMenuIndex || 0
    this.onSelected(this.menuIndex)
  },
  methods: {
    onSelected(index) {
      this.menuIndex = index
      this.menuList.forEach(item => {
        item.selected = false
      })
      this.menuList[index].selected = true
    },
    getBnf() {
      this.$tis.voice
        .getBnf()
        .then(res => {
          this.commandList = (res || '').split(/\|/g)
          this.instructList = [
            {
              name: '快捷指令',
              list: this.commandList
                .filter(str => str.match(/(打开)/i))
                .map(str => {
                  return {
                    name: str,
                    edit: false,
                    type: 'success',
                    hidden: false,
                  }
                }),
            },
            {
              name: '分屏操作',
              list: this.commandList
                .filter(str => str.match(/(分屏|切屏)/i))
                .map(str => {
                  return {
                    name: str,
                    edit: false,
                    type: 'success',
                    hidden: false,
                  }
                }),
            },
            {
              name: '其他',
              list: this.commandList
                .filter(str => !str.match(/(分屏|切屏|打开)/i))
                .map(str => {
                  return {
                    name: str,
                    edit: false,
                    type: 'success',
                    hidden: false,
                  }
                }),
            },
          ]
          console.log(this.instructList)
        })
        .catch(err => {
          console.log(err)
        })
    },
    onClose() {
      this.$tis.closeWidgetSettingPanel()
    },
    onTabClose(index, i) {
      this.activateAddBtnIndex = index
      this.instructList[index].list[i].hidden = true
      this.updateBnf()
    },
    async updateBnf(tag = '') {
      let list = this.instructList.map(item => item.list.filter(o => !o.hidden).map(o => o.name)).flat(1)
      list.push(tag)
      list = [...new Set(list)]
      await this.$tis.voice.updateBnf({
        bnf: list.join('|'),
      })
    },
    onSearchInput() {
      this.instructList.forEach(item => {
        item.list.map(o => {
          if (!this.searchValue || o.name.includes(this.searchValue)) {
            o.hidden = false
          } else {
            o.hidden = true
          }
        })
      })
    },
    s2vAndPlay() {
      this.playAfterS2vFlg = false
      if (this.s2vStr) {
        this.$tis.voice
          .getVoice({
            string: this.s2vStr,
            time: Date.now().toString(),
          })
          .then(res => {
            this.voideFileSrc = res
            this.playAfterS2vFlg = true
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    showAddTagDialog(index) {
      this.activateAddBtnIndex = index
      this.tagValue = null
      this.addTagVisible = true
    },
    async onMenuSelect(index) {
      this.menuIndex = index
    },
    async getSetting() {
      // 语音设置
      let {
        role = 3,
        speed = 5,
        tone = 5,
        volume = 5,
        iatoneType = 2,
        broadcast = 0,
      } = await this.$tis.voice.getSetting()
      this.voiceSetting = {
        role, // 人声
        speed, // 语速
        tone, // 语调
        volume, // 音量
        iatoneType, // 模式
        broadcast, // 语音播报
      }
    },
    showEditDialog(index, i) {
      this.editTagVisible = true
      this.editTagValue = this.instructList[index].list[i].name
      this.editIndex = [index, i]
    },
    onEditTag() {
      let [index, i] = this.editIndex
      this.instructList[index].list[i].name = this.editTagValue
      this.editTagVisible = false
      this.updateBnf()
    },
    async onAddTag() {
      if (this.tagValue) {
        this.addTagVisible = false
        await this.updateBnf(this.tagValue)
        this.getBnf()
      }
    },
  },
  computed: {
    messageSettingVisible() {
      return this.$tis.bus.messageSettingVisible
    },
  },
}
</script>
<style lang="scss" scoped>
.message-setting-container {
  position: relative;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 34px;
  box-sizing: border-box;
  // img {
  //   width: 1852px;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
  .wrap {
    width: 100%;
    display: flex;
    height: 100%;
    .w-left {
      width: 282px;
      background: #2a2927;
      padding-top: 20px;
      .item {
        height: 120px;
        font-size: 36px;
        color: #ffffff;
        line-height: 120px;
        opacity: 0.5;
        text-align: center;
        &.selected {
          opacity: 1;
          background: url(~@/assets/menu_bg.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .w-right {
      flex: 1;
      background: #232221;
      padding-left: 33px;
      padding-right: 10px;
      overflow-y: auto;
      .head {
        height: 110px;
        padding-top: 34px;
        .h-title {
          display: flex;
          height: 100%;
          align-items: center;
          font-size: 36px;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 54px;
          background: url(~@/assets/title-bg.png) no-repeat;
          background-size: 100% auto;
          img {
            width: 34px;
            margin-right: 34px;
          }
          span {
            padding-left: 150px;
          }
        }
      }
      .s-content {
        padding-top: 40px;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 70px;
          line-height: 70px;
          margin: 40px 0;
          padding: 0 34px;
          box-sizing: border-box;
          .left {
            font-size: 30px;
            color: #ffffff;
          }
          .right {
            text-align: right;
            font-size: 30px;
            color: #ffffff;
          }
          .right-content {
            // min-width: 569px;

            ::v-deep {
              & {
                .el-radio-group {
                  display: flex;
                  justify-content: space-between;
                  // width: 450px;
                }
                .el-radio {
                  width: 196px;
                }
              }
            }
          }
          .right-content_volume {
            min-width: 599px;
          }
          &.row4 {
            font-family: HiraginoSansGB-W3;
            font-size: 28px;
            color: #ffffff;
            margin-top: -20px;
          }
          &.row5 {
            height: 1px;
            border-bottom: 1px solid #686868;
            margin: 60px 0;
            width: calc(100% - 34px);
            padding-right: 34px;
          }
          &.row7 {
            .right {
              display: flex;
              align-items: center;
              > span {
                margin: 0 20px;
              }
              .input {
                opacity: 0.8;
                background: rgba(216, 216, 216, 0.06);
                border: 1px solid #979797;
                height: 80px;
                width: 300px;
                margin: 0 0px;
                color: #fff;

                box-sizing: border-box;

                ::v-deep .el-input {
                  width: 100%;
                  height: 100%;
                  padding: 0 20px;
                  box-sizing: border-box;
                  display: block;
                  .el-input__inner {
                    background: none;
                    border: none;
                    font-size: 30px;
                    height: 100%;
                  }
                  .el-input__prefix {
                    display: flex;
                    align-items: center;
                    left: 15px;
                    .el-input__icon {
                      font-size: 30px;
                      display: flex;
                      align-items: center;
                    }
                  }
                }
              }
            }
          }

          ::v-deep * {
            .el-radio {
              // display: flex;
              // align-items: center;
              .el-radio__inner {
                width: 36px;
                height: 36px;
                background: rgba(255, 255, 255, 0.11);
                &:after {
                  width: 24px;
                  height: 24px;
                  background-color: #f7c829;
                }
              }
              .el-radio__label {
                vertical-align: middle;
                font-family: HiraginoSansGB-W3;
                font-size: 30px;
                color: #ffffff;
              }
            }
          }
        }
        .w-title {
          padding: 0 34px;
          .wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 1px solid rgba(255, 255, 255, 0.6);
            height: auto;
            background: rgba(255, 255, 255, 0.06);
            text-align: left;
            font-size: 30px;
          }
          .thead {
            text-align: left;
            height: 100px;
            line-height: 100px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.6);
            padding: 0 34px;
          }
          .tbody {
            padding: 20px 0;
            .tr {
              text-align: left;
              height: 80px;
              line-height: 80px;
              padding: 0 34px;
            }
          }
        }

        .content6 {
          padding: 47px 34px 0 0;
          padding-bottom: 20px;

          ::v-deep {
            .el-tabs__header {
              padding-left: 54px;
              margin-bottom: 49px;
            }
            .el-tabs__item {
              font-size: 30px;
            }
            // .el-tabs__item.is-top{
            //   padding: 0 40px!important;
            // }
            .el-tabs__nav-wrap::after {
              height: 0;
            }
            // .el-tabs__active-bar{
            //   width: 200px!important;
            // }
            .button-new-tag {
              border: 1px solid rgba(255, 255, 255, 0.5);
              background: none;
              color: rgba(255, 255, 255, 0.5);
              margin-right: 20px;
              border-radius: 2px;
              padding: 22px 28px;
              & > span {
                font-size: 36px;
              }
            }

            .el-tag {
              font-size: 28px;
              margin-right: 20px;
              height: 80px;
              line-height: 80px;
              padding: 0 26px;
              background: none;
              border: 1px solid rgba(255, 255, 255, 0.5);
              border-radius: 2px;
              color: rgba(255, 255, 255, 0.5);
              margin-bottom: 10px;
              .el-tag__close {
                color: rgba(255, 255, 255, 0.5);
                width: 32px;
                height: 32px;
                &:before {
                  font-size: 20px;
                  border-radius: 100%;
                  width: 32px;
                  height: 32px;
                  background: rgba(255, 255, 255, 0.3);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
  ::v-deep {
    .el-dialog__title,
    .el-dialog__close {
      font-size: 30px;
    }
    .content {
      .el-input__inner {
        height: 80px;
        font-size: 28px;
      }
    }
    .dialog-footer {
      .el-button {
        font-size: 30px;
      }
    }
  }
  .search-content {
    display: flex;
    margin-top: 10px;
    padding-bottom: 20px;
    ::v-deep {
      .el-button {
        font-size: 30px;
      }
    }
  }
}
</style>

<style lang="scss">
.disturb-time {
  .time-select-item {
    font-size: 28px;
    opacity: 0.5;
    line-height: 30px;
  }
}
.el-tooltip__popper{
  line-height: 0.5;
  font-size: 20px;
}
</style>
