<template>
  <div class="voice-container">
    <div class="wrapper">
      <div class="boder">
        <img src="../assets/home/边框.svg" class="top-right" />
        <img src="../assets/home/边框.svg" class="top-left" />
        <img src="../assets/home/边框.svg" class="bottom-right" />
        <img src="../assets/home/边框.svg" class="bottom-left" />
      </div>
      <div class="title">语音助手</div>

      <div class="voice-list" ref="voiceList">
        <div class="row" :class="[item.position, item.type]" v-for="(item, index) in list" :key="index">
          <div class="wrap" v-if="item.type === 'text'">{{ item.content }}</div>
          <div class="wrap" v-else>
            <i class="iconfont icon-shishiliumoshi"></i>
            &nbsp;&nbsp;&nbsp;2s&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </div>
      <!-- 文字及语言输入 -->
      <div class="operation">
        <div class="row row1">
          <input type="text" v-model="voiceValue" @keyup.enter="onSend" />
          <div class="voice-icon" :class="{ activate: recordFlg }" @click="recordHandle"><i class="iconfont icon-huatong"></i></div>
        </div>
        <div class="row row2">
          <div class="o-icon" @click="showDrawer(0)"></div>
          <div class="o-icon o-icon2" @click="showDrawer(2)"></div>
        </div>
      </div>
      <div class="drawer-container" v-if="drawerVisible">
        <div class="header">
          <div class="left"><i class="iconfont icon-bangzhu" :class="{ activate: drawerContentIndex == 1 && drawerVisible }" @click="showDrawer(1)"></i></div>
          <div class="right">
            <div class="icon icon0" @click="onVoiceSetting"></div>
            <div class="icon icon1" @click.stop="onCloseVoiceAssistant"></div>
          </div>
        </div>
        <!-- 语音识别 -->
        <div class="content content0" v-if="drawerContentIndex == 0">
          <div class="wrap">
            <div class="text">{{ voiceValue }}</div>
            <div class="icon" :class="{ recording: wsRecording }" @click="onWsRecording"><img src="../assets/home/识别.png" alt="" /></div>
          </div>
        </div>
        <div class="content content1" v-if="drawerContentIndex == 1">
          <!-- 帮助 -->
          <div class="title">你可以说</div>
          <div class="list">
            <div class="item">
              <div class="row row0">组件操作</div>
              <div class="row row1">打开系统设置“分屏二”“切换主题”</div>
            </div>
            <div class="item">
              <div class="row row0">组件操作</div>
              <div class="row row1">打开系统设置“分屏二”“切换主题”</div>
            </div>
            <div class="item">
              <div class="row row0">组件操作</div>
              <div class="row row1">打开系统设置“分屏二”“切换主题”</div>
            </div>
          </div>
          <div class="footer">
            <div class="row row0">如何修改默认语音模式</div>
            <div class="row row1">打开系统设置 > 点击语音设置 > 进行默认模式切换</div>
          </div>
        </div>
        <div class="content content2" v-if="drawerContentIndex == 2">
          <!-- 菜单 -->
          <el-tabs v-model="value6">
            <template v-for="(item, index) in instructList">
              <el-tab-pane :label="item.name" :name="index.toString()" :key="index">
                <el-tag v-for="tag in item.list" :key="tag.name" :type="tag.type" v-show="!tag.hidden">{{ tag.name }}</el-tag>
              </el-tab-pane>
            </template>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="distinguish-content" v-if="recordFlg">
      <div class="wrap">
        <div class="left" @click.stop="recordHandle">
          <!-- <img src="../assets/home/暂停.svg" alt="" /> -->
          <i class="iconfont icon-zanting"></i>
        </div>
        <div class="right">
          <div class="row row1">
            <div class="row-left"><SoundJumpBoxPad /></div>
            <div class="row-right">{{ voiceTime }}s</div>
          </div>
          <div class="row row2">正在识别...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoundJumpBoxPad from './SoundJumpBoxPad';
import tisVoice from '../mixins/tisVoice';
import WSRecorder from '../utils/WSRecorder';

export default {
  mixins: [tisVoice],
  components: {
    SoundJumpBoxPad
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawerVisible: false,
      drawerContentIndex: 2,
      value: '',
      value6: '0',
      recordFlg: false,
      wsRecording: false,
      voiceValue: '',
      list: [
        {
          type: 'text', // Voice text
          position: 'left', // left right
          content: '聊天内容1'
        },
        {
          type: 'text',
          position: 'right',
          content: '聊天内容2'
        },
        {
          type: 'voice',
          position: 'left',
          content: '聊天内容3'
        },
        {
          type: 'voice',
          position: 'right',
          content: '聊天内容4'
        }
      ],
      instructList: [
        {
          name: '组件菜单',
          list: []
        },
        {
          name: '分屏操作',
          list: []
        },
        {
          name: '其他',
          list: []
        }
      ],
      duration: 0
    };
  },
  mounted() {
    this.getBnf();
    if (this.iatoneType == 3) {
      this.drawerVisible = true;
      this.drawerContentIndex = 0;
      this.showDrawer(0);
    }
  },
  computed: {
    iatoneType() {
      return this.$tis.bus.iatoneType;
    },
    visibleVoiceBox() {
      return this.$tis.bus.visibleVoiceBox;
    }
  },
  methods: {
    onWsRecording(type) {
      if (type) {
        this.wsRecording = type == 'start' ? true : false;
      } else {
        this.wsRecording = !this.wsRecording;
      }
      if (this.wsRecording) {
        this.voiceValue = '';
        WSRecorder.start(res => {
          res = JSON.parse(res);
          console.log(res);
          let resultList = res['result_list'];
          if (resultList) {
            // 自由说
            this.voiceValue = resultList[0].input;
          } else if (res['semText']) {
            // 命令词
            this.voiceValue = res['semText'];
          }
        });
      } else {
        WSRecorder.close();
      }
    },
    getBnf() {
      this.$tis.voice
        .getBnf()
        .then(res => {
          this.commandList = (res || '').split(/\|/g);
          this.instructList = [
            {
              name: '组件菜单',
              list: this.commandList
                .filter(str => str.match(/(打开)/i))
                .map(str => {
                  return {
                    name: str,
                    edit: false,
                    type: 'success',
                    hidden: false
                  };
                })
            },
            {
              name: '分屏操作',
              list: this.commandList
                .filter(str => str.match(/(分屏|切屏)/i))
                .map(str => {
                  return {
                    name: str,
                    edit: false,
                    type: 'success',
                    hidden: false
                  };
                })
            },
            {
              name: '其他',
              list: this.commandList
                .filter(str => !str.match(/(分屏|切屏|打开)/i))
                .map(str => {
                  return {
                    name: str,
                    edit: false,
                    type: 'success',
                    hidden: false
                  };
                })
            }
          ];
          console.log(this.instructList);
        })
        .catch(err => {
          console.log(err);
        });
    },
    onSend() {
      this.list.push({
        type: 'text',
        position: 'right',
        content: this.voiceValue
      });
      this.voiceValue = '';
      this.$nextTick(() => {
        this.$refs.voiceList.scrollTop = 99999;
      });
    },
    showDrawer(type) {
      this.drawerContentIndex = type;
      this.drawerVisible = true;
      this.onWsRecording('start');
    },
    onCloseDrawer() {
      this.drawerVisible = false;
      this.onWsRecording('close');
      // 关闭助手
      // this.$tis.toggleVoiceBox();
      // debugger
    },
    onCloseVoiceAssistant() {
      this.$tis.toggleVoiceBox();
    },
    onVoiceSetting() {
      this.onCloseDrawer();
      this.$tis.openWidgetSettingPanelPad(2);
    }
  },
  beforeDestroy() {
    this.onCloseDrawer();
    this.$tis.voice.recordEnd();
  },
  watch: {}
};
</script>
<style lang="scss" scoped>
.voice-container {
  position: fixed;
  top: 140px;
  left: 55%;

  .wrapper {
    width: 496px;
    height: 502px;
    background: #12100a;
    position: relative;

    .boder {
      display: block;
      margin: 0;
      width: 10px;

      > img {
        z-index: 10;
      }

      .top-left {
        position: absolute;
        top: 0;
        left: 0;
      }

      .top-right {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotateY(180deg);
      }

      .bottom-left {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: rotateX(180deg);
      }

      .bottom-right {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotateX(180deg) rotateY(180deg);
      }
    }

    .title {
      font-size: 30px;
      color: #ffc737;
      padding: 20px;
    }

    .voice-list {
      padding: 0 33px;
      font-size: 16px;
      color: #ffffff;
      line-height: 24px;
      height: 236px;
      overflow: auto;

      .row {
        margin-bottom: 14px;
        font-family: PingFangSC-Regular;
        font-size: 28px;
        color: #ffffff;
        line-height: 24px;
      }

      .left {
        display: flex;
        justify-content: flex-start;

        .wrap {
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.5);
          padding: 18px 22px 20px;
          position: relative;

          &:after {
            content: '';
            width: 10px;
            height: 100%;
            left: -9px;
            top: 0;
            position: absolute;
            background-image: url(../assets/home/arrow.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;

        .wrap {
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.5);
          padding: 18px 22px 20px;
          position: relative;

          &:after {
            content: '';
            transform: rotateY(180deg);
            width: 10px;
            height: 100%;
            right: -9px;
            top: 0;
            position: absolute;
            background-image: url(../assets/home/arrow.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .operation {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      background: rgba(255, 255, 255, 0.05);
      padding: 24px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;

      .row1 {
        // width: 448px;
        display: flex;
        align-items: center;
        height: 80px;
        line-height: 80px;
        margin-bottom: 20px;
        background: rgba(255, 255, 255, 0.05);
        border: 1px solid rgba(255, 255, 255, 0.7);
        position: relative;

        input {
          width: 100%;
          height: 100%;
          background: none;
          border: none;
          color: rgba(255, 255, 255, 0.9);
          padding: 0 13px;
          font-size: 20px;
        }

        .voice-icon {
          position: absolute;
          right: 10px;
          // top: 14px;
          width: 22px;
          height: 22px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 24px;
          }

          &.activate i {
            color: #e1af32;
          }
        }
      }

      .row2 {
        display: flex;

        .o-icon {
          width: 30px;
          height: 30px;
          margin-right: 30px;
          background-image: url(../assets/home/实时流模式.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;

          &.o-icon2 {
            background-image: url(../assets/home/快捷指令.svg);
          }
        }
      }
    }
  }

  .drawer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 383px;
    background: #1e1c17;
    border-top: 1px solid rgba(255, 255, 255, 0.6);

    .header {
      display: flex;
      justify-content: space-between;
      padding: 10px 16px;

      .left {
        .icon {
          width: 30px;
          height: 30px;
          background-image: url(../assets/home/帮助.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .icon-bangzhu {
          font-size: 30px;

          &.activate {
            color: #f7c829;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        .icon {
          margin-left: 18px;
          width: 30px;
          height: 30px;
          background-image: url(../assets/home/设置.svg);
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }

        .icon1 {
          background-image: url(../assets/home/收起.svg);
          width: 30px;
          height: 30px;
        }
      }
    }

    .content0 {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .text {
        margin: 60px 0 70px;
        font-size: 30px;
      }

      .icon {
        text-align: center;
        width: 90px;

        img {
          width: 100%;
        }

        &.recording {
          img {
            animation: recording 2.5s linear infinite;
          }
        }
      }
    }

    .content1 {
      padding-bottom: 10px;

      .title {
        padding: 16px 20px;
        font-size: 30px;
        color: #ffffff;
      }

      .list {
        padding: 0px 18px;

        .item {
          padding: 8px 18px;
          border: 1px solid rgba(70, 68, 68, 0.7);
          font-size: 24px;
          color: #ffffff;
          margin-bottom: 10px;
          font-family: HiraginoSansGB-W6;
          line-height: 32px;
          font-weight: 100;

          .row0 {
            font-size: 30px;
          }

          .row1 {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      .footer {
        padding: 8px 18px;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 10px;
        font-family: HiraginoSansGB-W6;
        line-height: 32px;
        font-weight: 100;

        .row0 {
          font-size: 30px;
        }

        .row1 {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }

    .content2 {
      padding: 10px 20px;

      ::v-deep {
        .el-tabs__item,
        .el-tag {
          font-size: 24px;
        }

        .el-tab-pane {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;

          .el-tag {
            padding: 6px 10px;
            height: unset;
            line-height: unset;
          }
        }
      }

      .el-tabs__nav-wrap::after {
        display: none;
      }

      .el-tag {
        cursor: pointer;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .el-tabs {
        min-height: 180px;
      }

      .wrap {
        display: flex;
        flex-wrap: wrap;

        .btn {
          padding: 0 10px;
          height: 44px;
          line-height: 44px;
          font-family: HiraginoSansGB-W3;
          font-size: 16px;
          color: #ffffff;
          background: rgba(255, 255, 255, 0.1);
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .distinguish-content {
    position: absolute;
    width: 283px;
    height: 88px;
    background: #1e1c16;
    border: 1px solid #979797;
    border-radius: 44px;
    left: 50%;
    margin-left: -140px;
    bottom: 200px;

    .wrap {
      display: flex;
      align-items: center;
      height: 100%;

      .left {
        width: 60px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;

        img {
          width: 43px;
          height: 43px;
          position: relative;
          top: -1px;
        }

        i {
          color: #ffc737;
          font-size: 58px;
        }
      }

      .right {
        font-size: 24px;
        margin-left: 20px;
        color: #fff;

        .row1 {
          display: flex;

          // padding-top: 9px;
          // padding-bottom: 3px;
          .row-left {
            width: 120px;
            // height: 16px;
            // background-image: url(../assets/home/音频.png);
            // background-repeat: no-repeat;
            // background-size: 100% 100%;
          }
        }

        .row2 {
          opacity: 0.35;
          font-family: HiraginoSansGB-W6;
          font-size: 28px;
          color: #ffffff;
        }
      }
    }
  }
}

@keyframes recording {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
