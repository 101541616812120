<!-- 警报 发警报 -->
<template>
  <div
    class="alarm-container"
    v-if="alarmMainWineowVisible || alarmSendVisible"
  >
    <div class="alarm-content" v-if="alarmMainWineowVisible">
      <img src="~@/assets/alarm1.png" alt="" />
      <div class="a-btn" @click="onSendAlarmShow">
        <img src="~@/assets/alarm-btn.png" alt="" srcset="" />
      </div>
    </div>
    <div class="send-alarm-content" v-if="alarmSendVisible">
      <div class="mask" @click="onSendAlarmHide"></div>
      <img src="~@/assets/alarm2.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {
    onSendAlarmShow() {
      this.$tis.bus.alarmSendVisible = true;
    },
    onSendAlarmHide() {
      this.$tis.bus.alarmSendVisible = false;
    },
  },
  computed: {
    alarmMainWineowVisible() {
      return this.$tis.bus.alarmMainWineowVisible;
    },
    alarmSendVisible() {
      return this.$tis.bus.alarmSendVisible;
    },
  },
};
</script>
<style lang="scss" scoped>
.alarm-container {
  width: 100%;
  height: 100%;
  background: rgb(27 25 26);
  .alarm-content {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .a-btn {
      width: 230px;
      height: 80px;
      position: absolute;
      right: 0px;
      top: 30px;
      left: auto;
    }
    img {
      width: 100%;
    }
  }
  .send-alarm-content {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    .mask {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
    }
    img {
      position: absolute;
      width: 636px;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 3;
    }
  }
}
</style>
