<template>
  <!-- 跳动音符 -->
  <div class="sound-jump-box">
    <ul>
      <li
        v-for="item in jumpArr"
        :key="item.id"
        :id="'li_' + item.id"
        :style="{ 'animation-delay': item.value / 100 + 's' }"
      ></li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jumpArr: [],
    };
  },
  computed: {},
  mounted() {
    this.jumpSound();
  },
  methods: {
    jumpSound() {
      this.jumpArr = [];
      for (let i = 0; i < 12; i++) {
        let obj = {
          id: i,
          value: Math.floor(Math.random() * 70),
        };
        this.jumpArr.push(obj);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sound-jump-box {
  width: 120px;
  height: 20px;
  position: absolute;
  overflow: hidden;
  position: relative;
  top: 3px;

  ul {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    clear: both;

    li {
      width: 3px;
      height: 20px;
      background-color: #fff;
      float: left;
      margin: 0 4px;
      transform-origin: bottom;
      transform: scaleY(0.1);
      animation: dancing 0.8s ease-in-out infinite;
    }

    @keyframes dancing {
      0% {
        transform: scaleY(0.1);
      }
      50% {
        transform: scaleY(1);
      }
      100% {
        transform: scaleY(0.1);
      }
    }
  }
}
</style>
